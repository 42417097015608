import React from 'react'

import gasfitting from '../../img/services/gasfitting.jpg'
import gasfittingThumb from '../../img/services/gasfitting_thumb.jpg'
import Layout from '../../components/layout'

const Gasfitting = () => (
  <Layout>
    <div className="box">
      <h2 className="stripe">Gasfitting Services</h2>

      <div className="serviceImage">
        <a className="fancybox" href={gasfitting} title="Typical Domestic Gas Installation" rel="fancybox">
          <img src={gasfittingThumb} width="250"/>
        </a>
        <p>Typical Domestic Gas Installation</p>
      </div>

      <p>
        A fully certified tradesman runs our gasfitting department. We believe it is important to employ only licensed
        tradesman not “laborers” to ensure your next gas job is done correctly. Gas is quickly becoming the most
        popular,
        more efficient way to heat your home, hot water and operate your stove. Our services offer many ways you can
        switch to gas and save money in the long term.
      </p>

      <div id="tvservicesListOne" style={{ float: 'left' }}>
        <h3>Gas Services we offer include:</h3>
        <ul className="servicesList">
          <li>Gas hot water cylinders and wall units.</li>
          <li>Gas pre pipework for hobs or fires.</li>
          <li>Maintenance for existing gas appliances.</li>
          <li>Servicing regulators.</li>
          <li>Installation of gas fires or hobs.</li>
          <li>One off gas jobs including engineering burners.</li>
        </ul>
      </div>

      <div id="tvservicesListTwo" style={{ float: 'left', marginLeft: '20px' }}/>

      <br style={{ clear: 'left' }}/>

      <div id="tvservicesBottomText" style={{ float: 'left', width: '600px' }}>
        <p>We can quote any gas work you may have for free and talk you through the process of switching over. We can
          sign
          off all our work carried out with an issued certificate. Our gasfitter can also show you a pressure test
          carried
          out to give you further piece of mind.
        </p>
      </div>
      <br className="clear"/>
    </div>
  </Layout>
)

export default Gasfitting
